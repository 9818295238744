/**
 * Entry point with only minimal dependencies, to handle minor interactivity
 * and tracking for components that are not rehydrated after being server side
 * rendered.
 */
import {
  createDomTrackingListener,
  getPageLoadTrackingData,
} from '@volvo-cars/tracking/domTracking';
import { measureWebVitals } from '@volvo-cars/tracking/webVitals';
import { readSSRFeatureFlags } from '@vcc-www/dotcom-feature-flags/readSSRFeatureFlags';
import { GA3Event } from '@volvo-cars/tracking';

const { 'use-ga-4-tracking': useGA4Tracking, coreWebVitalsReporting } =
  readSSRFeatureFlags();

const mode = useGA4Tracking ? 'both' : 'ga3';

// Create dataLayer and push start event as soon as possible
const dataLayer = (window.dataLayer || (window.dataLayer = [])) as any[];
dataLayer.push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

const pageLoadTrackingData = getPageLoadTrackingData({
  mode,
});

pageLoadTrackingData.forEach((trackingData) => {
  dataLayer.push(trackingData);
});

document.getElementById('root')?.addEventListener(
  'click',
  createDomTrackingListener('onClick', {
    mode,
  }),
);

const ga3 = pageLoadTrackingData.find(
  (data) => data.event !== 'page_view',
) as GA3Event;

const ga4 = pageLoadTrackingData.find((data) => data.event === 'page_view');

if (coreWebVitalsReporting) {
  measureWebVitals({
    options: {
      mode,
      ...((mode === 'both' || mode === 'ga3') && {
        ga3: {
          ...ga3,
          eventCategory: 'web vitals',
        },
      }),
    },
    additionalEventData: ga4,
  });
}
